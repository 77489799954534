<template>
  <div v-if="showCheckbox">
    <div class="PageCheckoutInfo__createAccount">
      <div class="PageCheckoutInfo__registration">
        <div
          v-bind="$attrs"
          class="form-group checkbox-custom">
          <input
            id="form__createAccount"
            v-model="isRegistration"
            class="form-check-input"
            name="createAccount"
            type="checkbox">
          <label
            class="form-check-label"
            for="form__createAccount">
            {{ $t('globals.checkboxes.registration.heading') }}
          </label>
        </div>
        <template v-if="isRegistration">
          <div class="form-group row mt-3">
            <label
              class="col-form-label col-md-8"
              for="new-password">
              {{ $t('globals.checkboxes.registration.password') }}&nbsp;<RequiredSign/>
            </label>
            <div class="col-md-16">
              <AppInput
                id="new-password"
                ref="password"
                v-model="password"
                :data-vv-as="$t('globals.checkboxes.registration.password')"
                :show-valid-indicator="false"
                validation="required|min:8"
                name="password"
                type="password"/>
            </div>
          </div>
          <div class="form-group row mb-0">
            <label
              class="col-form-label col-md-8"
              for="new-password-confirmation">
              {{ $t('globals.checkboxes.registration.passwordConfirmation') }}&nbsp;<RequiredSign/>
            </label>
            <div class="col-md-16">
              <AppInput
                id="new-password-confirmation"
                v-model="passwordConfirmation"
                :data-vv-as="$t('globals.checkboxes.registration.notifications.passwordsDontMatch')"
                :show-valid-indicator="false"
                validation="required|min:8|password_confirmation"
                name="passwordConfirmation"
                type="password"/>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import AppInput from '@theme/components/atom/AppInput'
import ProvideValidator from '~/app/ProvideValidator'
import RequiredSign from '@theme/components/atom/RequiredSign'

export default {
  components: {
    AppInput,
    RequiredSign,
  },
  mixins: [ProvideValidator],
  inheritAttrs: false,
  computed: {
    isRegistration: {
      get() {
        return this.$store.state.cart.input.isRegistration
      },
      set(value) {
        this.$store.commit('cart/SET_CART_INPUT_FIELD', { field: 'isRegistration', value: value })
      },
    },
    password: {
      get() {
        return this.$store.state.cart.input.password
      },
      set(value) {
        this.$store.commit('cart/SET_CART_INPUT_FIELD', { field: 'password', value: value })
      },
    },
    passwordConfirmation: {
      get() {
        return this.$store.state.cart.input.passwordConfirmation
      },
      set(value) {
        this.$store.commit('cart/SET_CART_INPUT_FIELD', { field: 'passwordConfirmation', value: value })
      },
    },
    showCheckbox() {
      return !this.$store.getters['user/userIsLoggedIn']
    },
  },
  created() {
    const passwordConfirmValidation = {
      getMessage: (field, args) => {
        return this.$t('globals.checkboxes.registration.notifications.passwordsDontMatch')
      },
      validate: (value, args) => {
        return this.passwordConfirmation === this.password
      },
    }
    this.$validator.extend('password_confirmation', passwordConfirmValidation, {
      immediate: false,
    })
  },
}
</script>
